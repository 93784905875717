import { AuthContext } from "common/context/Auth";
import dynamic from "next/dynamic";
import AuthButton from "./AuthButton";
import { useContext } from "react";
import { AuthWrapper } from "./Auth.styled";
const UserDropDown = dynamic(() => import("./UserDropDown"));

const AuthComponent = () => {
  const { session } = useContext<any>(AuthContext);

  return (
    <AuthWrapper>
      {!session ? (
        <AuthButton type="login">Login</AuthButton>
      ) : (
        <UserDropDown />
      )}
    </AuthWrapper>
  );
};

export default AuthComponent;
