import { FC, useState } from "react";
import dynamic from "next/dynamic";
import {SeachIcon, SeachWrapper, SeachButton, SeachWrapperMobile} from "./Search.styled";
import { useRouter } from "next/dist/client/router";
import SearchIcon from "common/icons/search";
import { useDevice } from "../../common/hooks/useDevice";

const Input = dynamic(() => import("Components/Input"), { ssr: false });


type Props = {
  type: string;
};

const Search: FC<Props> = ({ type }) => {
  const { push, pathname } = useRouter();
  const [value, setValue] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);
  const { screenSize } = useDevice();
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  // Check if the current path starts with "/search" and return null if it does
  if (pathname.startsWith("/search")) {
    return null;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    push(`/search?search=${value}`);
  };

  const handleButtonClick = () => {
    setInputVisible(!isInputVisible);
  };

  if (type === "row") {
    return (
      <form onSubmit={handleSubmit}>
        {screenSize.small ? (
            <SeachWrapperMobile>
              <Input
                handleChange={handleChange}
                value={value}
                placeholder="Search"
              />{" "}
              <SeachButton type="submit">
                <SearchIcon/>
              </SeachButton>
            </SeachWrapperMobile>
        ) : (
          <SeachWrapper isVisible={isInputVisible}>
            {isInputVisible && (
              <Input
                handleChange={handleChange}
                value={value}
                placeholder="Search"
              />
            )}
            <SeachButton type="button" onClick={handleButtonClick}>
              <SearchIcon />
            </SeachButton>
          </SeachWrapper>
        )}
      </form>
    );
  } else {
    return (
      <>
        <SeachIcon />
      </>
    );
  }
};

export default Search;
