import { FC } from "react";
import { TertiaryMenuWrapper } from "../Menu.styled";
import Link from "next/link";
import { Route, StyledRouteSecondayTitle } from "../Menu.styled";
import { TertiarySubMenuProps } from "common/Layout/types/Layout.types";

const TertiaryMenu: FC<TertiarySubMenuProps> = ({ tertiarysubMenu }) => {
  if (!tertiarysubMenu) {
    return null;
  }
  return (
    <TertiaryMenuWrapper>
      {tertiarysubMenu?.map((item, i) => (
        <a key={i} href={item.path}>
          <Route>
            <StyledRouteSecondayTitle>{item.title}</StyledRouteSecondayTitle>
          </Route>
        </a>
      ))}
    </TertiaryMenuWrapper>
  );
};

export default TertiaryMenu;
