import { useEffect, useState } from "react";
import { defaultBreakpoints } from "styled-media-query";
import { useMediaQuery } from "react-responsive";

export type Device = {
  screenSize: {
    extra_large: boolean;
    large: boolean;
    medium: boolean;
    small: boolean;
  };
  nativeSharing: boolean;
};

export const useDevice = (): Device => {
  const [isNativeShare, setNativeShare] = useState(false);
  const ScreenSize = {
    extra_large: useMediaQuery({
      query: `(max-width: ${defaultBreakpoints.huge})`,
    }),
    large: useMediaQuery({ query: `(max-width: ${defaultBreakpoints.large})` }),
    medium: useMediaQuery({
      query: `(max-width: ${defaultBreakpoints.medium})`,
    }),
    small: useMediaQuery({ query: `(max-width: ${defaultBreakpoints.small})` }),
  };

  useEffect(() => {
    if (navigator.share !== undefined) {
      setNativeShare(true);
    }
  }, []);

  return {
    screenSize: ScreenSize,
    nativeSharing: isNativeShare,
  };
};
