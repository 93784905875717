import {  useContext } from "react";
import DropDown from "Components/DropDown";
import { User, Tab, SignOutTab } from "./UserDropDown.styled";
import Link from "next/link";
import { AuthContext } from "common/context/Auth";
import { authTypes } from "common/context/Auth/AuthContext";

const UserDropDown = () => {
  const { SignOut } = useContext<authTypes>(AuthContext);
  return (
    <DropDown Icon={User} arrow={false} type="auth">
      <>
        <Link href="/account">
          <Tab>Account</Tab>
        </Link>
        <SignOutTab onClick={() => SignOut()}>Log out</SignOutTab>
      </>
    </DropDown>
  );
};

export default UserDropDown;
