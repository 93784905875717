import usePopupWindow from "common/hooks/usePopUpWindow";
import { FC, createElement } from "react";
import {
  SocialLinkStyled,
  SocialLinkStyledWrapper,
  StyledSpan,
} from "./SocialLink.styled";
import TwitterIcon from "../../common/icons/twitter";
import EmailIcon from "../../common/icons/email";
import FacebookIcon from "../../common/icons/facebook";

type IconProps = {
  type: string;
  publication: {
    url: string;
  };
  article: {
    title: string;
  };
};

const icons: {
  [key: string]: FC<IconProps>;
} = {
  twitter: TwitterIcon,
  email: EmailIcon,
  facebook: FacebookIcon,
};

type Props = {
  type: string;
  url: string;
  text?: string;
  dark?: boolean;
};

const SocialLink: FC<Props> = ({ url, type, text, dark }) => {
  const popupWindow = usePopupWindow();

  const handleTwitterShareClick = (): void => {
    if (type === "email") {
      window.location.assign(url);
    } else {
      popupWindow(url);
      {
        /* if (!nativeSharing) {
        popupWindow(url);
      } else {
        const shareData = {
          title: nativeSharingTitle,
          text: nativeSharingText,
          url: nativeSharingUrl,
        };
        navigator.share(shareData);
      }*/
      }
    }
  };

  return (
    <SocialLinkStyledWrapper href={url} target="_blank">
      <SocialLinkStyled dark={dark} shareType={type}>
        {createElement(icons[type])}
      </SocialLinkStyled>
      {text && <StyledSpan>{text}</StyledSpan>}
    </SocialLinkStyledWrapper>
  );
};

export default SocialLink;
