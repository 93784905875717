import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import { FC, ReactNode, useContext } from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { LayoutProps } from "../types/Layout.types";

type Props = {
  content?: string;
  children: ReactNode;
  favicon?: string;
  title?: string;
  data: LayoutProps;
  canonicalUrl?: string;
  header_nav?: any;
};

const Frame: FC<Props> = ({
  content = "",
  children,
  favicon = "/favicons/tindle_crest.ico",
  canonicalUrl,
  title,
  data,
  header_nav,
}) => {
  const { query } = useRouter();
  const { publication, config } =
    useContext<publicationTypes>(PublicationContext);
  const { primary_route } = query;

  return (
    <div style={{ background: "white" }}>
      <Head>
        <link rel="canonical" href={canonicalUrl ? canonicalUrl : ""} />
        <script async id="ebx" src="//applets.ebxcdn.com/ebx.js" />
        <title>{`${title ? title : publication} | ${
          config.pub ? config.pub : primary_route ? primary_route : "local news"
        }`}</title>
        <link rel="icon" href={favicon} />
        <meta name="google-site-verification" content={publication} />{" "}
      </Head>
      <NavBar
        fullMenu={data?.full_nav}
        weather={data?.weather}
        menu={data?.nav_bar.menu}
        headerNav={data.header_nav.menu}
      />

      <main style={{ minHeight: 850, background: "white" }}>{children}</main>
      <Footer footer={data?.footer} contactDetails={data?.contactDetails} />
    </div>
  );
};

export default Frame;
