import styled from "styled-components";
import UserIcon from "common/icons/user";

export const User = styled(UserIcon)`
  font-size: 35px;
  margin: auto 0;
  color: ${({ theme }) => theme.default.color.text.medium};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const Tab = styled.div`
  border: 0;
  background: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.light}`};
  cursor: pointer;
  color: ${({ theme }) => theme.default.color.primary};
`;

export const SignOutTab = styled.div`
  border: 0;
  background: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.light}`};
  text-transform: uppercase;
  cursor: pointer;
  color: red;
`;
