import { cropImage } from "@brightsites/flow-core/lib/utils/cropImage";

export const imageHeroUrl = (
  publication: string,
  image: string,
  cropData: object = {},
  mobile?: boolean,
  size?: number
) => {
  {
    /*  if (
      
      //process.env.NEXT_PUBLIC_ENV_STATE === "prod"
      
      ) {
      try {
          if (mobile) {
              return `${image}?width=455&height=350&crop=455:350,smart&quality=75`;
          } else {
              return `${image}?width=669&height=445&crop=669:445,smart&quality=75`;
          }
      } catch (err) {
          return image
      }
  } else */
  }
  try {
    if (size === 1) {
      return `${image}?${cropImage(
        cropData
      )}width=950&height=6450&crop=950:645`;
    }
    if (mobile) {
      return `${image}?${cropImage(cropData)}width=455&height=350&crop=455:350`;
    } else {
      return `${image}?${cropImage(cropData)}width=669&height=445&crop=669:445`;
    }
  } catch {
    return image;
  }
};

export const  imageHeroUrlPortrait  = (
  publication: string,
  image: string,
  cropData: object = {},
  mobile?: boolean,
  size?: number
) => {
  {
    /*  if (
        
        //process.env.NEXT_PUBLIC_ENV_STATE === "prod"
        
        ) {
        try {
            if (mobile) {
                return `${image}?width=455&height=350&crop=455:350,smart&quality=75`;
            } else {
                return `${image}?width=669&height=445&crop=669:445,smart&quality=75`;
            }
        } catch (err) {
            return image
        }


        /// image cropping for dev
                if (size === 1) {
            return `${image}?${cropImage(cropData)}width=950&height=6450&crop=950:645`;
        }
        if (mobile) {
            return `${image}?${cropImage(cropData)}width=455&height=350&crop=455:350`;
        } else {
            return `${image}?${cropImage(cropData)}width=669&height=445&crop=669:445`;

        }
    } else */
  }
  try {
    if (size === 1) {
      return `${image}?width=950`;
    }
    if (mobile) {
      return `${image}?width=455`;
    } else {
      return `${image}?width=669`;
    }
  } catch {
    return image;
  }
};

export const imageItemUrl = (
  publication: string,
  image: string,
  size: number,
  mpu?: boolean,
  role?: string,
  mobile?: boolean
) => {
  try {
    let width = size === 3 ? 209 : 119;
    let height = size === 3 ? 140 : 79;
    let crop = size === 3 ? "&crop=209:145,smart" : "&crop=119:79,smart";
    let itemx2 = `${image}?width=209&height=145&crop=240:145,smart&quality=75`;
    let itemx2Mobile = `${image}?width=96&height=64&crop=64:96,smart&quality=75`;
    let itemx3_plus_MPU = `${image}?width=209&height=145&crop=240:145,smart&quality=75`;
    let itemx3 = `${image}?width=${width}&height=${height}${crop}&quality=75`;

    if (mobile === true) {
      return itemx2Mobile;
    }
    if (role === "job") {
      return image;
    }
    if (size === 2 && mobile === false) {
      return itemx2;
    }

    return mpu ? itemx3_plus_MPU : itemx3;
  } catch {
    return image;
  }
};
