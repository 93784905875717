import { transparentize } from "polished";
import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const NewsLetterSignUpWrapper = styled.div`
  background-color: ${({ theme }) => theme.default.color.background};
  border-radius: 16px;  
  padding: 0.5rem 6rem; 
  margin-top: 20px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    padding: 0.5rem 0;
  }
  .newsletter_card {
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    box-shadow: none;
    margin: 0;  
  }
  .checkbox {
    label {
      font-family: ${({theme}) => theme.default.fontFamily.secondaryFont};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-left: 6px;  
    }
    a {
      text-decoration: underline;
    }
  }
  h1 {
    font-family:${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;  
    margin-bottom: 10px  
  }  
`;
export const Info = styled.span`
  font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;  
`;
export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;  
  margin-bottom: 10px;
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;  

  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
`;

export const StyledForm = styled.form``;

export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.default.color.primary};
  color: ${({ theme }) => theme.default.color.text.white};
  position: absolute;
  height: 40px;
  align-items: center;
  right: 0;
  top: 1px;
  border: 0;
  width: 90px;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-family:${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  :hover {
    background-color: #216CA8;
  }
  :focus{
    background-color: #014984;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Title = styled.h4`
  text-transform: uppercase;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.default.color.primary};
`;
