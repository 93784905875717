import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { useRouter } from "next/router";
import { useContext } from "react";
import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

const Link = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  width: 350px;
  height: 85px;
  object-fit: contain;
  margin: 0 auto;
  cursor: pointer;
  @media (max-width: ${defaultBreakpoints.large}) {
    width: 250px;
    margin: auto;
    height: 70px;
  }
  @media (max-width: ${defaultBreakpoints.small}) {
    width: 220px;
    margin: auto;
    height: 70px;
  }
`;

const Logo = () => {
  const router = useRouter();
  const { publication } = useContext<publicationTypes>(PublicationContext);

  const preview =
    typeof router?.query?.domain === "string" && router?.query?.domain;

  const logos: { [unit: string]: string } = {
    ["petersfieldpost.co.uk"]: "/images/logos/peters.jpg",
    ["farnhamherald.com"]: "/images/logos/FarnhamHerald.jpg",
    ["altonherald.com"]: "/images/logos/AltonHerald.jpg",
    ["bordonherald.com"]: "/images/logos/bordan_herald.jpg",
    ["haslemereherald.com"]: "/images/logos/haslemere_herald.jpg",
    ["liphookherald.com"]: "/images/logos/lipton.jpg",
    ["abergavennychronicle.com"]: "/images/logos/abergavennychronicle_2.jpg",
    ["monmouthshirebeacon.co.uk"]: "/images/logos/monmouthshirebeacon.jpg",
    ["chepstowbeacon.co.uk"]: "/images/logos/chepstowbeacon.jpg",
    ["brecon-radnor.co.uk"]: "/images/logos/brecon_radnor.jpg",
    ["cambrian-news.co.uk"]: "/images/logos/cambrian_news.jpg",
    ["tenby-today.co.uk"]: "/images/logos/tenby_today.jpg",
    ["narberth-and-whitland-today.co.uk"]:
      "/images/logos/narberth_and_whitland_today.jpg",
    ["pembroke-today.co.uk"]: "/images/logos/pembroke.jpg",
    ["theforester.co.uk"]: "/images/logos/theforester.jpg",
    ["rossgazette.com"]: "/images/logos/rossgazette.jpg",
    ["theforestreview.co.uk"]: "/images/logos/theforestreview_2.jpg",
    ["tavistock-today.co.uk"]: "/images/logos/TavistockTimes1.jpg",
    ["middevonadvertiser.co.uk"]: "/images/logos/MidDevonAdvertiser.jpg",
    ["dawlish-today.co.uk"]: "/images/logos/DawlishGazette.jpg",
    ["teignmouth-today.co.uk"]: "/images/logos/TeignmouthPost.jpg",
    ["dartmouth-today.co.uk"]: "/images/logos/DartmouthChronicle.jpg",
    ["ivybridge-today.co.uk"]: "/images/logos/Ivybridge&SouthBrentGazette.jpg",
    ["kingsbridge-today.co.uk"]: "/images/logos/kingsbridge-today.jpg",
    ["southhams-today.co.uk"]: "/images/logos/kingsbridge-today.jpg",
    ["totnes-today.co.uk"]: "/images/logos/TotnesTimes.jpg",
    ["tamarvalleytimes.co.uk"]: "/images/logos/tamarvalleytimes.jpg",
    ["okehampton-today.co.uk"]: "/images/logos/okehampton-today.jpg",
    ["creditoncourier.co.uk"]: "/images/logos/creditoncourier.jpg",
    ["iomtoday.co.im"]: "/images/logos/iom_logo.jpg",
    ["voicenewspapers.co.uk"]: "/images/logos/voice.jpg",

    ["mnrjournal.co.uk"]: "/images/logos/mnr.jpg",
    ["wokingnewsandmail.co.uk"]: "/images/logos/woking.jpg",
    ["wsfp.co.uk"]: "/images/logos/west_sumerset.jpg",
    ["wellington-today.co.uk"]: "/images/logos/wellington.jpg",
    ["holsworthy-today.co.uk"]: "/images/logos/holsworth.jpg",
    ["cornish-times.co.uk"]: "/images/logos/cornish_times.jpg",
    ["thepost.uk.com"]: "/images/logos/cornish.jpg",
    ["bude-today.co.uk"]: "/images/logos/Bude.jpg",
  };
  const selectImage = () => {
    if (!preview) {
      return "";
    }
    if (preview === "petersfieldherald.com") {
      return "/images/logos/peters.jpg";
    } else {
      return logos[preview];
    }
  };
  if (!publication) {
    return null;
  }
  const renderSrcsetLogo = (isMobileLogo: boolean) => {
    if (isMobileLogo) {
      return `${logos[publication]}?width=220&height=70&crop=220:70`
    } else {
      return `${logos[publication]}?width=350&height=85&crop=350:85`
    }
  }
  return (
    <Link href="/">
      {preview ? (
        <Image src={selectImage()} alt={preview} id="logo"/>
      ) : (
        <Image
          src={logos[publication]}
          alt={publication}
          id="logo"
          loading="lazy"
          srcSet={`
            ${renderSrcsetLogo(true)} 220w,
            ${renderSrcsetLogo(false)} 350w,
        `}
        />
      )}
    </Link>
  );
};

export default Logo;
