const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="icon/facebook">
      <path id="Vector"
            d="M11.9999 1.43994C6.16794 1.43994 1.43994 6.16794 1.43994 11.9999C1.43994 17.2943 5.33994 21.6657 10.4217 22.4294V14.7988H7.80906V12.023H10.4217V10.1759C10.4217 7.11786 11.9116 5.7753 14.4532 5.7753C15.6705 5.7753 16.3142 5.86554 16.619 5.90682V8.32986H14.8852C13.8062 8.32986 13.4294 9.35274 13.4294 10.5057V12.023H16.5916L16.1625 14.7988H13.4294V22.4519C18.5836 21.7526 22.5599 17.3457 22.5599 11.9999C22.5599 6.16794 17.8319 1.43994 11.9999 1.43994Z"
            fill="#272727"/>
    </g>
  </svg>
);

export default FacebookIcon;
