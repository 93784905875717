import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  col?: number;
  type?: string;
};

export const three = css`
  grid-template-columns: 1fr 1fr 1fr;
  width: 845px;
  right: -20px;
`;
export const One = css`
  grid-template-columns: 1fr;
  width: 250px;
  right: 0;
  position: absolute;
  display: grid;
  z-index: 2000;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 13px 0;
  top: 40px;
  grid-template-rows: 1fr 1fr 1fr;
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
    right: 0;
    padding: 20px 0;
    top: 50px;
  }
`;
export const Auth = css`
  right: 0;
  position: absolute;
  display: grid;
  z-index: 2000;
  min-width: 100px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 65px;
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0;
    right: 0;
    padding: 20px 0;
    top: 50px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      right: -10px;
      top: 70px;
      padding: 0;
    }
  }
`;
export const SectionChildren = styled.div<StyledProps>`
  ${({ col }) => col === 1 && One}
  ${({ type }) => type === "auth" && Auth}
`;
