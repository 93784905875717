import { FC, useContext, useRef } from "react";
import { useRouter } from "next/dist/client/router";
import {
  PrimaryMenuWrapper,
  Route,
  StyledRouteTitle
} from "../Menu.styled";
import { MenuProps } from "common/Layout/types/Layout.types";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { renderFaceBookUrl, renderTwitterUrl } from "common/Utils/social";

interface Props {
  setSecondayMenu: (x: any) => void;
  setTertiarysubMenu: (x: any) => void;
  menu: MenuProps[];
}

const PrimaryMenu: FC<Props> = ({
  menu,
  setSecondayMenu,
  setTertiarysubMenu,
}) => {
  const router = useRouter();
  const { config } = useContext<publicationTypes>(PublicationContext);

  const twitterUrl = useRef(renderTwitterUrl(router, config));
  const facebookUrl = useRef(renderFaceBookUrl(router, config));
  // Check if the current path starts with "/search"
  const isSearchPage = router.pathname.startsWith("/search");

  const handleSelected = (listItem: { path: string }) => {
    const item = listItem.path.substring(listItem.path.indexOf("/") + 1);
    const route = router.asPath.substring(router.asPath.indexOf("/") + 1);
    const secondaryRoute = router.asPath.substring(
      router.asPath.indexOf("/") + 1,
      router.asPath.lastIndexOf("/")
    );

    const splitRoute = route.split("/");

    if (router.asPath === listItem.path) {
      return true;
    }
    if (splitRoute[0] === item) {
      return true;
    }
    if (item === route) {
      return true;
    }
    if (item === secondaryRoute) {
      return true;
    }
    if (route.length === 0 && item === "/") {
      return true;
    }
    if (route === "" && item === "") {
      return true;
    }
    if (route === undefined && item === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleMouseHover = (children: any) => {
    setTertiarysubMenu(null);

    if (children?.length > 0) {
      setSecondayMenu(children);
    } else {
      setSecondayMenu(null);
    }
  };

  if (!menu) {
    return <></>;
  }
  return (
    <PrimaryMenuWrapper>
      {menu?.map((listItem, i) => {
        if (listItem.title === "more") {
          return (
            <Route
              key={i}
              onMouseEnter={() => handleMouseHover(listItem.children)}
            >
              <StyledRouteTitle selected={handleSelected(listItem)}>
                {listItem.title}
              </StyledRouteTitle>
            </Route>
          );
        }
        return (
          <a key={i} href={listItem.path}>
            <Route onMouseEnter={() => handleMouseHover(listItem.children)}>
              <StyledRouteTitle selected={handleSelected(listItem)}>
                {listItem.title}
              </StyledRouteTitle>
            </Route>
          </a>
        );
      })}
    </PrimaryMenuWrapper>
  );
};

export default PrimaryMenu;
