import { FC } from "react";
import {
  Route,
  StyledRouteSecondayTitle,
  SecondaryMenuWrapper,
} from "../Menu.styled";
import { SecondarySubMenuProps } from "common/Layout/types/Layout.types";

const SecondaryMenu: FC<SecondarySubMenuProps> = ({
  secondaysubMenu,
  setTertiarysubMenu,
}) => {
  if (!secondaysubMenu) {
    return null;
  }
  return (
    <SecondaryMenuWrapper>
      {secondaysubMenu?.map((item, i) => {
        if (item.children) {
          return (
            <a key={i} href={item.path}>
              <Route>
                <StyledRouteSecondayTitle
                  onMouseEnter={() =>
                    setTertiarysubMenu(
                      item.children?.length > 0 ? item.children : null
                    )
                  }
                >
                  {item.title}
                </StyledRouteSecondayTitle>
              </Route>
            </a>
          );
        }
        return (
          <a key={i} href={item.path}>
            <Route onMouseEnter={() => setTertiarysubMenu(null)}>
              <StyledRouteSecondayTitle>{item.title}</StyledRouteSecondayTitle>
            </Route>
          </a>
        );
      })}
    </SecondaryMenuWrapper>
  );
};

export default SecondaryMenu;
