import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { setDefaultImage } from "common/Utils/defaults";
import { imageItemUrl } from "common/Utils/images";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Image, Item, Title, Wrapper } from "./Topics.styled";

const Topics = () => {
  const [data, setData] = useState<any>(null);
  const { publication } = useContext<publicationTypes>(PublicationContext);

  const FetchData = async () => {
    try {
      const fetchedData = await axios.get(
        `/api/most_popular?publication=${publication}&number=${3}`
      );

      if (fetchedData?.status === 404 || !Array.isArray(fetchedData.data)) {
        setData(null);
      } else {
        setData(fetchedData.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setData(null);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <Title className="topics-title">Most popular</Title>
        {data &&
          data?.map(
            (
              link: {
                title: string;
                path: string;
                hierarchy: { name: string }[];
                hero: { data: { url: string } }[];
              },
              i: number
            ) => {
              if (!link) {
                return null;
              }
              if (!link.path) {
                return null;
              }
              if (!link.hero) {
                return null;
              }
              if (!link.hierarchy) {
                return null;
              }
              const image = link.hero[0].data.url
                ? link.hero[0].data.url
                : link.hierarchy &&
                  link.hierarchy[0]?.name &&
                  setDefaultImage(link.hierarchy[0]?.name);

              return (
                <a key={i} href={link.path}>
                  <Item>
                    {" "}
                    {image && (
                      <Image src={imageItemUrl(publication, image, 3)} />
                    )}
                    <span>{link.title}</span>
                  </Item>
                </a>
              );
            }
          )}
      </Wrapper>
    </>
  );
};

export default Topics;
