import { FC, useState } from "react";
import {
  Arrow,
  ChildWrapper,
  StyledItem,
  Title,
  TitleWrapper,
} from "../../FullMenu.styled";

type Props = {
  menuItem: Item;
  closeMenu: () => void;
};

type Item = {
  title: string;
  path: string;
  children: Array<object>;
};
const MenuSection: FC<Props> = ({ menuItem, closeMenu }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <TitleWrapper>
        <Title href={menuItem.path} onClick={closeMenu}>
          {menuItem.title}
        </Title>

        <Arrow open={open} onClick={() => setOpen(!open)} />
      </TitleWrapper>
      {menuItem.children && (
        <ChildWrapper open={open}>
          {menuItem.children.map((child: any, i) => (
            <StyledItem key={i} href={child.path} onClick={closeMenu}>
              {child.title}
            </StyledItem>
          ))}
        </ChildWrapper>
      )}
    </div>
  );
};

export default MenuSection;
