import { FC } from "react";
import { SectionChildren } from "./Children.styed";
interface Props {
  children: any;
  open: boolean;
  col?:number;
  type: string;
}

const Children: FC<Props> = ({ open, children, type, col }) => {
  if (open) {
    return <SectionChildren type={type} col={col}>{children}</SectionChildren>;
  }
  return null;
};

export default Children;
