import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const DefaultTitleStyle = css`
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SectionTitleStyle = css`
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; 
  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const AccountTitleStyle = css`
  margin-bottom: 14px;
  font-size: 40px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    margin-bottom: 0px;
    font-size: 25px;
    line-height: 25px;
  }
`;

export const CardTitleStyle = css`
  text-transform: uppercase;
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.default.color.primary};
`;

export const SuccessTitleStyle = css`
 font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  margin-bottom: 14px;
  letter-spacing: 0.05em;
  font-size: 50px;
  line-height: 58.59px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    margin-bottom: 0px;
    font-size: 25px;
    line-height: 25px;
  }
`;

export const TopicsTitleStyle = css`
    font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      font-size: 20px;
      line-height: 24px;
    }
`;
export const JobsTitleStyle = css`
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 20px;
  margin-top: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AuhtorTitleStyle = css`
  text-align: left;
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 52px;
  font-style: normal;
  line-height: 60px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; 
  }
`;

export const ServiceTitleStyle = css`
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 24px;
    line-height: 32px;
    padding-top: 8px;
  }
`;
type StyledProps = {
  type?: string;
};
export const StyledTitle = styled.h1<StyledProps>`
  ${({ type }) => type === "default" && DefaultTitleStyle}
  ${({ type }) => type === "topics" && TopicsTitleStyle}
  ${({ type }) => type === "author" && AuhtorTitleStyle}
  ${({ type }) => type === "success" && SuccessTitleStyle}
  ${({ type }) => type === "card" && CardTitleStyle}
  ${({ type }) => type === "account" && AccountTitleStyle}
  ${({ type }) => type === "jobs" && JobsTitleStyle}
  ${({ type }) => type === "section" && SectionTitleStyle}
  ${({ type }) => type === "service" && ServiceTitleStyle}
`;
