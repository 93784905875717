import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    padding-right: 15px;
  }

  @media (min-width: ${defaultBreakpoints.large}) {
    display: none;
  }

`;

export const IconsWrapper = styled.div`
  display: flex;
  margin-left: 0;
`;

export const Break = styled.div`
  width: 10px;
  height: 100%;
`;
