import React, { FC, useState, FocusEventHandler, ReactNode } from "react";
import {
  InputStyled,
  StyledLabel,
  InputError,
  WrapperInputError,
  InputWrapper,
  CheckBoxWrapper,
  InputBottomError,
  IconWrapper,
} from "./Field.styled";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
type Props = {
  inputType?: "text" | "email" | "password" | "tel" | "checkbox";
  placeholder?: string;
  label?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  register?: any;
  error?: string | undefined | false;
  name?: string;
  inputStyles?: string;
  required?: boolean;
  pattern?: string;
  prefix?: string;
  checkbox?: ReactNode;
  bottom_error?: any;
  className?: string;
};

const Field: FC<Props> = ({
  inputType = "text",
  placeholder,
  handleChange,
  value,
  name,
  error,
  inputStyles = "default",
  onBlur,
  label,
  required,
  pattern,
  prefix,
  checkbox,
  bottom_error,
  className
}) => {
  const [type, setType] = useState(inputType);
  return (
    <>
      {error && (
        <WrapperInputError>
          <InputError id="error">{error}</InputError>
        </WrapperInputError>
      )}
      <InputWrapper className={className}>
      {label &&
      <StyledLabel>
        {label}
        {required && <span> *</span>}
      </StyledLabel>
      }
        {prefix && <span>{prefix}</span>}
        <InputStyled
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          name={name}
          data-testid="input"
        />
        <IconWrapper>
          {inputType === "password" && (
            <>
              {type === "password" ? (
                <AiOutlineEye onClick={() => setType("text")} />
              ) : (
                <AiOutlineEyeInvisible onClick={() => setType("password")} />
              )}
            </>
          )}
        </IconWrapper>
        {checkbox && <CheckBoxWrapper>{checkbox}</CheckBoxWrapper>}
        {bottom_error && (
          <WrapperInputError>
            <InputBottomError>{bottom_error}</InputBottomError>
          </WrapperInputError>
        )}
      </InputWrapper>
    </>
  );
};

export default Field;
