import { FC } from "react";
import { CheckboxWrapper, StyledLabel } from "./CheckBox.styled";
import parse from "html-react-parser";

type Props = {
  label: string;
  name?: string;
  className?: string;
  value?: any;
  handleChange?: any;
};

const Checkbox: FC<Props> = ({ label, handleChange, value, name, className }) => (
  <CheckboxWrapper className={className}>
    <input name={name} onChange={handleChange} value={value} type="checkbox" />
    <StyledLabel>
      {" "}
      <span>{parse(label)}</span>
    </StyledLabel>
  </CheckboxWrapper>
);

export default Checkbox;
