import { FC, useState } from "react";
import SecondaryMenu from "./SecondaryMenu";
import PrimaryMenu from "./PrimaryMenu";
import TertiaryMenu from "./TertiaryMenu";
import { Wrapper } from "./Menu.styled";
import { MenuProps } from "common/Layout/types/Layout.types";

type Props = {
  menu: MenuProps[];
};

const Menu: FC<Props> = ({ menu }) => {
  const [secondaysubMenu, setSecondayMenu] = useState(undefined);
  const [tertiarysubMenu, setTertiarysubMenu] = useState(undefined);
  const handleMouseLeave = () => {
    setSecondayMenu(undefined);
    setTertiarysubMenu(undefined);
  };
  return (
    <Wrapper onMouseLeave={() => handleMouseLeave()}>
      <PrimaryMenu
        setTertiarysubMenu={setTertiarysubMenu}
        setSecondayMenu={setSecondayMenu}
        menu={menu}
      />
      <SecondaryMenu
        setTertiarysubMenu={setTertiarysubMenu}
        secondaysubMenu={secondaysubMenu}
      />
      <TertiaryMenu tertiarysubMenu={tertiarysubMenu} />
    </Wrapper>
  );
};

export default Menu;
