import { FC, useRef, useEffect } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import {
  FullMenuWrapper,
  StyledHamburgerWrapper,
} from "./FullMenu.styled";
import { FullMenuProps } from "common/Layout/types/Layout.types";

interface Props {
  setFullMenuOpen: (x: boolean) => void;
  openFullMenu: boolean;
  fullMenu: FullMenuProps;
}

const FullMenu: FC<Props> = ({ fullMenu, openFullMenu, setFullMenuOpen }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      window.innerWidth > 1170 && // Only activate on desktop
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setFullMenuOpen(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 1170) {
      // Add the event listener only on desktop
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FullMenuWrapper ref={wrapperRef}>
      <StyledHamburgerWrapper>
        <Hamburger
          size={20}
          hideOutline={true}
          toggled={openFullMenu}
          toggle={() => setFullMenuOpen(!openFullMenu)}
        />
      </StyledHamburgerWrapper>
    </FullMenuWrapper>
  );
};

export default FullMenu;
