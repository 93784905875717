import styled from "styled-components";
import {defaultBreakpoints} from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  margin: 24px 0;
`;

export const Item = styled.div`
  display: flex;
  cursor: pointer;
  background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-radius: 20px;
  span {
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: 12px;
    color: ${({ theme }) => theme.default.color.text.dark};  
    :hover {
      color: ${({ theme }) => theme.default.color.primary};
      text-decoration: underline;
    }
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 127px;
  height: 96px;
  border-radius: 20px 0 0 20px;
  @media (max-width: ${defaultBreakpoints.medium}) {
      border-radius: 20px 20px 0 0;
      width: 100%;
      height: 100%;
  }
`;

export const Title = styled.span`
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;
`;
