import styled, { css } from "styled-components";
import { defaultBreakpoints } from "styled-media-query";
import { Formik, Field, Form, FormikHelpers } from "formik";

type StyledProps = {
  inputStyles?: string;
};

export const InputFrostedStyle = css`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.default.color.backgrounds.light};
  border: 0;
  box-shadow: ${({ theme }) => `0 0 0 0 ${theme.default.color.text.light}`}
    inset;
  -webkit-appearance: none;
  height: 40px;
`;

export const formInput = css`
  height: 40px;
  border-radius: 20px;
`;

export const formDefault = css`
  height: 40px;
  border-radius: 20px;
`;

export const InputStyled = styled(Field)<StyledProps>`
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  outline: 0;
  width: 100%;
  margin: auto 0;
  border: 0;
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  
  &:focus {
    border: ${({ theme }) =>  `1px solid ${theme.default.color.primary}`};
  }

  &:hover {
   border: ${({ theme }) =>  `1px solid ${theme.default.color.primary}`};
  }
   
  &::placeholder {
    color: grey;
  }
  border-radius: 20px;
  background-color: ${({ theme }) => theme.default.color.backgrounds.white};
  border: ${({ theme }) => `1px solid ${theme.default.color.text.light}`};
  height: 40px;
`;

export const InputError = styled.span`
  color: red;
  font-size: 12px;
  padding-left: 1rem;
  position: absolute;
  bottom: 5px;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
`;

export const InputBottomError = styled.span`
  color: red;
  font-size: 12px;
  margin-left: 5px;
  //position: absolute;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  //top: -8px;
`;

export const IconWrapper = styled.span`
  right: 0;
  top: 12px;
  position: absolute;
`;

export const WrapperInputError = styled.div`
  position: relative;
  white-space: nowrap;
`;
export const StyledLabel = styled.p`
  font-weight: bold;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  span {
    color: ${({ theme }) => theme.default.color.primary};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  position: relative;
  span {
    margin-right: 5px;
  }
`;

export const CheckBoxWrapper = styled.div`
  width: 400%;
`;
