export const setDefaultImage = (title: string) => {
    if (title === "Home") {
      return "/images/cat_images/News.jpg";
    }
    if (title === "Sport") {
      return "/images/cat_images/Sport.jpg";
    }
    if (title === "Opinion") {
      return "/images/cat_images/Opinion.jpg";
    }
    if (title === "999") {
      return "/images/cat_images/999.jpg";
    }
    if (title === "Business") {
      return "/images/cat_images/Business.jpg";
    }
    if (title === "Courts") {
      return "/images/cat_images/Courts.jpg";
    }
    if (title === "Education") {
      return "/images/cat_images/Education.jpg";
    }
    if (title === "Health") {
      return "/images/cat_images/Health.jpg";
    }
    if (title === "Planning") {
      return "/images/cat_images/Planning.jpg";
    }
    if (title === "Politics") {
      return "/images/cat_images/Politics.jpg";
    }
    if (title === "Bowls") {
      return "/images/cat_images/Bowls.jpg";
    }
    if (title === "Cricket") {
      return "/images/cat_images/Cricket.jpg";
    }
    if (title === "Football") {
      return "/images/cat_images/Football.jpg";
    }
    if (title === "Golf") {
      return "/images/cat_images/Golf.jpg";
    }
    if (title === "Rugby") {
      return "/images/cat_images/Rugby.jpg";
    }
    return "/images/cat_images/News.jpg";
  };