export const renderFaceBookUrl = (router: any, config: any) => {
    try {
        if (typeof router?.query?.seconday_route === "string") {
            if (typeof config.facebook === "string") {
                return `https://www.facebook.com/${config.facebook}`;
            } else {
                if (typeof config.facebook[router?.query?.seconday_route] === 'undefined') {
                    return null
                } else {
                return config.facebook[router?.query?.seconday_route];
            }}
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};
export const renderTwitterUrl = (router: any, config: any) => {
    try {
        if (typeof router?.query?.seconday_route === "string") {
            if (typeof config.twitter === "string") {
                return `https://twitter.com/${config.facebook}`;
            } else {
                if (typeof config.twitter[router?.query?.seconday_route] === 'undefined') {
                    return null
                } else {
                    return `https://twitter.com/${config.twitter[router?.query?.seconday_route]
                        }`;
                }
            }
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};