import { useRef, useEffect } from "react";

export const useMountedRef = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    });
  }, []);

  return mountedRef;
};
