import styled, { keyframes } from "styled-components";
import { AiOutlineDown } from "react-icons/ai";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  open?: boolean;
};

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

export const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`;
export const StyledHamburgerWrapper = styled.div`
  padding: 0;

  @media (max-width: ${defaultBreakpoints.medium}) {
    padding: 0;
  }
`;

export const CollapsedMenuSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${defaultBreakpoints.medium}) {
    a {
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledMenu = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 86px;
  z-index: 1000;
  padding: 3rem 0;
  background-color: white;
  width: 100%;
  transition: max-height 0.05s ease-in;
  animation: ${(props) => (props.open ? fadeIn : fadeOut)} 0.2s linear;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
  border-bottom: ${({ theme }) => `1px solid ${theme.default.color.text.dark}`};  
  border-top: ${({ theme }) => `1px solid ${theme.default.color.text.dark}`};
  margin-top: 16px;


    @media (max-width: ${defaultBreakpoints.large}) {
    top: 71px;
    padding: 0;
    height: calc(100vh - 51px);
    overflow-y: scroll;
    flex-direction: column;
    border: 0;
    margin-top: 0;    

    a {
      display: flex;
      min-height: 40px;
      align-items: center;
    }
  }
`;

export const StyledSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 200px);
  gap: 15px 10px;
  margin: 0 auto;

   @media (min-width: ${defaultBreakpoints.large}) {
    margin: 0 0 0 auto;
   }
 
  @media (max-width: ${defaultBreakpoints.medium}) {
    gap: 0px;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 2rem);
    margin: 0 auto;
    border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.dark}`};
  }

`;

export const FullMenuWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
`;

export const TopicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;

    .topics-title {
      display: none;
    }
    @media (max-width: ${defaultBreakpoints.large}) {
          display: none;
    }
`

export const SubHeading = styled.p`
    font-family:  ${({ theme }) =>  theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;
`

export const ChildWrapper = styled.section<StyledProps>`
  display: flex;
  flex-direction: column;
  @media (max-width: ${defaultBreakpoints.medium}) {
    max-height: ${(props) => (props.open ? "100%" : "0")};
    overflow: hidden;
    padding: ${(props) => (props.open ? "5px 0" : "0")};
    transition: all 0.2s ease;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${defaultBreakpoints.medium}) {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const Title = styled.a`
font-size: 12px;
font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
font-style: normal;
font-weight: 800;
line-height: 12px; 
text-transform: uppercase;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.default.color.primary};
    border-bottom: ${({ theme }) => `1px solid ${theme.default.color.primary}`};
  }
`;

export const StyledItem = styled.a`
  padding: 5px 0;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.default.color.primary};
  }

`;

export const Arrow = styled(AiOutlineDown)<StyledProps>`
  transform: ${(props) => props.open && "rotate(180deg)"};
  transition: transform 0.4s ease;
  margin-left: auto;
  font-size: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: ${defaultBreakpoints.medium}) {
    font-size: 1.4rem;
    padding: 0.1rem;
    line-height: 22px;
    line-height: 22px;
    height: 22px;
    margin: auto;
    margin-right: 0;
  }
`;

export const StyledDivider = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  margin: auto 0 1rem 0;
  background-color: ${({ theme }) => `${theme.default.color.text.light}`};
`;

export const SearchBarWrapper = styled.div`
  padding: 10px 10px 0 10px;
  margin-bottom: 36px;
  margin-top: 25px;
`;
