import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  shareType: string;
  dark?: boolean;
};

const shareColors: { [index: string]: string } = {
  facebook: "#1977f3",
  twitter: "#0f1419",
  email: "#b49a43",
};

const hoverColors: { [index: string]: string } = {
  facebook: "#509BFF",
  twitter: "#4D2C00",
  email: "#b49a43",
};

export const SocialLinkStyledWrapper = styled.a`
  display: flex;
  align-items: center;
`;

export const SocialLinkStyled = styled.div<StyledProps>`
  border-radius: 25px;
  width: 20px;
  height: 20px;
  text-decoration: none;
  display: flex;
  border: 0;
  outline: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;

  svg {
    color: ${({ shareType }) => shareColors[shareType]};
    fill: ${({ dark, shareType }) => (dark ? "white" : shareColors[shareType])};
    font-size: ${({ dark }) => (dark ? "16px" : "20px")};
    :hover {
      fill: ${({ dark, shareType }) =>
        dark ? "white" : hoverColors[shareType]};
    }
  }
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.default.color.text.dark};
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
`;
