import { FC, useState } from "react";
import FullMenu from "./FullMenu";
import Logo from "Components/Logo";
import Social from "./Social";
import WeatherWidget from "Components/WeatherWidget";
import AuthComponent from "common/Auth";
import { Wrapper, InnerWrapper } from "./Topbar.styled";
import { FullMenuProps } from "common/Layout/types/Layout.types";
import { Weather } from "Components/WeatherWidget/WeatherWidget";
import { useDelayUnmount } from "common/hooks/useDelayUnmount";
import FullMenuBody from "./FullMenu/FullMenuBody";
import { useDevice } from "common/hooks/useDevice";
import {PrimaryMenuSearchWrapper} from "../Menu/Menu.styled";
import Search from "../../../../Components/Search";
import {useRouter} from "next/dist/client/router";

type Props = {
  fullMenu: FullMenuProps;
  weather: Weather;
  headerNav: any;
};

const Topbar: FC<Props> = ({ fullMenu, weather, headerNav }) => {
  const router = useRouter();
  const [openFullMenu, setFullMenuOpen] = useState(false);
  const shouldRenderChild = useDelayUnmount(openFullMenu, 180);
  const { screenSize } = useDevice();
  // Check if the current path starts with "/search"
  const isSearchPage = router.pathname.startsWith("/search");
  return (
    <Wrapper>
    {screenSize.large ? (
      <>
        <FullMenu
        openFullMenu={openFullMenu}
        setFullMenuOpen={setFullMenuOpen}
        fullMenu={fullMenu}/>
        <Logo/>
        <Social openFullMenu={openFullMenu} />
        {shouldRenderChild && (
          <FullMenuBody
            fullMenu={fullMenu}
            openFullMenu={openFullMenu}
            setFullMenuOpen={setFullMenuOpen}
          />
        )}
      </>
      ) : (
      <>
      <InnerWrapper>
        <FullMenu
        openFullMenu={openFullMenu}
        setFullMenuOpen={setFullMenuOpen}
        fullMenu={fullMenu}
        />
        <WeatherWidget weather={weather} />
      </InnerWrapper>
        <Logo />
        <Social openFullMenu={openFullMenu} />
        <PrimaryMenuSearchWrapper isSearchPage={isSearchPage}>
          <Search type="row" />
        </PrimaryMenuSearchWrapper>
        <AuthComponent />
        {shouldRenderChild && (
          <FullMenuBody
            fullMenu={fullMenu}
            openFullMenu={openFullMenu}
            setFullMenuOpen={setFullMenuOpen}
          />
        )}
     </>
    )}
    </Wrapper>
  );
};

export default Topbar;
