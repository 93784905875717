import { FC } from "react";
import { useDevice } from "common/hooks/useDevice";
import Divider from "Components/Divider/Divider";
import { Wrapper } from "./DateTime.styled";

type Props = {
  time: Date;
  type?: string;
};

const DateTime: FC<Props> = ({ time, type }) => {
  const { screenSize } = useDevice();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const shortMonth = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const nth = function (d: number) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  if (type === "day") {
    return <>{days[time.getDay()]}</>;
  }

  if(type === "time") {
    return <> {formatAMPM(time)}</>;
  }

  return (
    <Wrapper>
      {screenSize.large || type === "short" ? (
        <>
          {" "}
          {time.getDate()}{nth(time.getDate())} {shortMonth[time.getMonth()]} {time.getFullYear()}
        </>
      ) : (
        <>
          {" "}
          {days[time.getDay()]}  {time.getDate()}
          {nth(time.getDate())} {month[time.getMonth()]} {time.getFullYear()} {" "}
          {formatAMPM(time)}
        </>
      )}
    </Wrapper>
  );
};

export default DateTime;
