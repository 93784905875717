import { useState, FC, ReactNode } from "react";
import dynamic from "next/dynamic";
import { Login } from "../Auth.styled";
const Modal = dynamic(() => import("Components/Modal"));
const AuthModal = dynamic(() => import("../AuthModal"));


type Props = {
  type: string;
  className?: string;
  children: ReactNode;
};

const AuthButton: FC<Props> = ({ children, type, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Login className={className} onClick={() => setOpen(true)}>{children}</Login>
      {open && (
        <Modal onClose={() => setOpen(false)}>
          <AuthModal type={type} />
        </Modal>
      )}
    </>
  );
};

export default AuthButton;
