import { FC, useContext } from "react";
import { useState } from "react";
import Checkbox from "Components/CheckBox";
import { FiSend } from "react-icons/fi";
import {SubmitButton, Grid, InputWrapper, NewsLetterSignUpWrapper, Info} from "./NewsLetterSignUp.styled";
import Axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Field from "Components/Field";
import { isEmpty } from "common/Utils/formik";
import Card from "Components/Card";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";

interface Props {
  type?: string;
  hideNewsLetter?: boolean;
  className?: string;
}

const SignSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const NewsLetterSignUp: FC<Props> = ({ type = "", hideNewsLetter = false, className }) => {
  const { publication, config } = useContext<publicationTypes>(PublicationContext);
  const [state, setState] = useState({
    error: "",
    success: false,
  });

  if (hideNewsLetter) {
    return null;
  }
  if (type === "Commercial") {
    return null;
  }

  return (
    <NewsLetterSignUpWrapper className={className}>
      <Card className='newsletter_card' title="Newsletter Sign up">
          <Grid className="grid_container">
            <Info>
              {!state.success
                ? "Get your latest local news for free in your email inbox"
                : "Thanks for signing up to our newsletter!"}
            </Info>
            {!state.success && (
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={async (values) => {
                  try {
                    await Axios.post("/api/newsletter", {
                      email: values.email,
                      publication
                    });
                    setState({
                      error: "",
                      success: true,
                    });
                  } catch (e) {
                    console.log(e);
                    setState({
                      error: "you have already signed up",
                      success: false,
                    });
                  }
                }}
                validationSchema={SignSchema}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  errors,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <InputWrapper>
                      <Field
                        inputStyles="form"
                        name="email"
                        value={values.email}
                        handleChange={handleChange}
                        placeholder="Enter your email address"
                        bottom_error={
                          (touched.email && errors.email) ||
                          (state.error !== "" && state.error)
                        }
                      />
                      <SubmitButton
                        type="submit"
                        disabled={
                          isEmpty(errors) || values.email === "" || isSubmitting
                        }
                      >
                        Submit
                      </SubmitButton>
                    </InputWrapper>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
          {!state.success && (
            <Checkbox className='checkbox'
              label={`I would like to be emailed about offers, events and updates from ${config.pub}. Read our <a href="/privacy" >privacy notice</a>`}
            />
          )}
      </Card>
    </NewsLetterSignUpWrapper>
  );
};

export default NewsLetterSignUp;
