import styled from "styled-components";
import { AiFillCaretRight } from "react-icons/ai";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  open?: boolean;
  type?: string;
};

export const Title = styled.p<StyledProps>`
  background-color: ${({ theme, type }) =>
    type === "form" && theme.default.color.backgrounds.light};
  color: ${({ theme, open }) => open && theme.default.color.primary};
  transition: background-color 0.4s ease;
  padding: 10px;
  white-space: nowrap;
  @media (max-width: ${defaultBreakpoints.large}) {
    padding: 0;
  }
`;

export const Arrow = styled(AiFillCaretRight)<StyledProps>`
  transform: ${(props) => props.open && "rotate(90deg)"};
  transition: transform 0.4s ease;
  margin: auto 0px auto auto;
  font-size: 1.2rem;
  cursor: pointer;
`;
