import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Icon = styled.img`
  height: 30px;
  width: 30px;
  margin: auto 0.2rem;
`;

export const TemperatureWrapper = styled.div``;

export const Wrapper = styled.a`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  margin: 0 11px 0 25px;
  cursor: pointer;
  color: ${({ theme }) => theme.default.color.text.dark};
    // @media (max-width: ${defaultBreakpoints.large}) {
  //   display: none;
  // }
`;

export const CelsiusWrapper = styled.div`
  color: ${({ theme }) => theme.default.color.text.dark};
`;
