import { FC } from "react";
import {
  Title,
  TitleWrapper,
  CollapsedMenuSectionWrapper,
} from "../../FullMenu.styled";

type Props = {
  menuItems: Item[];
};

type Item = {
  title: string;
  path: string;
};
const CollapsedMenuSection: FC<Props> = ({ menuItems }) => {
  return (
    <CollapsedMenuSectionWrapper>
      {menuItems?.map((item, i) => (
        <TitleWrapper key={i}>
          <Title  href={item.path}>
            {item.title}
          </Title>
        </TitleWrapper>
      ))}
    </CollapsedMenuSectionWrapper>
  );
};

export default CollapsedMenuSection;
