import { FC, ReactNode } from "react";
import Title from "Components/Title";
import { Wrapper } from "./Card.styled";

interface Props {
  title?: string;
  children: ReactNode;
  tab?: string;
  className?: string;
}

const Card: FC<Props> = ({ title, tab, children, className }) => {
  return (
    <Wrapper className={className}>
      {tab && <><span className="tab">{tab}</span></>}
      {title && <Title type="card">{title}</Title>}
      {children}
    </Wrapper>
  );
};

export default Card;
