import { transparentize } from "polished";
import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.default.color.background};
  border-radius: 16px;
  display: grid;
  row-gap: 12px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    padding: 1rem;
  }
  .tab {
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.default.color.primary};
    text-align: center;  
  }
`;
