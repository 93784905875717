import { Weather } from "Components/WeatherWidget/WeatherWidget";
import { FC } from "react";
import { FullMenuProps, MenuProps } from "../types/Layout.types";
import Menu from "./Menu";
import TopBar from "./TopBar";
import {Navbar} from "./TopBar/Navbar.styled";

type Props = {
  fullMenu: FullMenuProps;
  menu: MenuProps[];
  weather: Weather;
  headerNav: any;
};

const NavBar: FC<Props> = ({ fullMenu, menu, weather, headerNav }) => {
  return (
    <Navbar>
      <TopBar fullMenu={fullMenu} weather={weather} headerNav={headerNav} />
      <Menu menu={menu} />
    </Navbar>
  );
};

export default NavBar;
