import { FC, ReactNode } from "react";
import { StyledTitle } from "./Title.styled";

interface Props {
  children?: ReactNode | string;
  type?: string;
}

const Title: FC<Props> = ({ children, type="default" }) => {
  return <StyledTitle type={type}>{children}</StyledTitle>;
};

export default Title;
