import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 85px;
  grid-template-columns: 1fr 2fr 1fr 55px;
  justify-content: space-between;
  position: relative;
  padding: 0 16px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr 2fr 1fr;
  }

  @media (min-width: 1171px) {
    padding: 0 52px;
  }
  
  @media (max-width: ${defaultBreakpoints.large}) {
    grid-template-rows: 70px;
    grid-template-columns: 0.4fr 2fr 0.4fr;
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.default.color.text.dark}`};
  }

  .right {
    display: flex;
    justify-content: flex-end;

     @media (max-width: ${defaultBreakpoints.large}) {
     justify-content: flex-start;
     }
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justfify-content: flex-start;
`
