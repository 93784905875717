type PopupWindow = (
  url: string,
  widowWidth?: number,
  windowHeight?: number
) => Window | null;

const usePopupWindow = (): PopupWindow => {
  const popupWindow = (
    url: string,
    windowWidth = 500,
    windowHeight = 600
  ): Window | null => {
    return window.open(
      url,
      undefined,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${windowWidth}, height=${windowHeight}, top=0, left=0`
    );
  };

  return popupWindow;
};

export default usePopupWindow;
