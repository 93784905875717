import styled from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import { defaultBreakpoints } from "styled-media-query";

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;  
  .comment-login {
    font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
    border: 0;
    background: ${({ theme }) => theme.default.color.primary};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 20px;
    border-radius: 24px;
    color: ${({ theme }) => theme.default.color.backgrounds.white};
    align-self: flex-end;
    margin-top: 8px;
  }  
`;

export const Login = styled.button`
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-size: 12px;
  font-style: normal;
  cursor: pointer;
  font-style: normal;
  font-weight: 800;
  line-height: 12px; 
  text-transform: uppercase;
  background: none;
  border: 0px;
  color: ${({ theme }) => theme.default.color.text.dark};
`;

export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  
  @media (max-width: ${defaultBreakpoints.large}) {
    display: none;
  }
`;
