import { FC, useContext } from "react";
import { Wrapper } from "./Social.styled";
import { AuthContext } from "common/context/Auth";
import AuthButton from "common/Auth/AuthButton";
import UserDropDown from "common/Auth/UserDropDown";
import { defaultBreakpoints } from "styled-media-query";
import UserIcon from "common/icons/user";

type Props = {
  openFullMenu: boolean;
};

const Social: FC<Props> = ({ openFullMenu }) => {
  const { session } = useContext<any>(AuthContext);

  return (
    <Wrapper>
        {!session ? (
          <AuthButton type="login">{defaultBreakpoints.medium ? <UserIcon/> : 'Login'}</AuthButton>
        ) : (
          <UserDropDown />
        )}
    </Wrapper>
  );
};

export default Social;
