import DateTime from "Components/DateTime";
import { FC } from "react";
import {
  Icon,
  Wrapper,
  CelsiusWrapper,
  TemperatureWrapper,
} from "./WeatherWidget.styled";


export type WeatherProps = {
  weather: Weather;
};

export type Weather = {
  current: {
    last_updated_epoch: number;
    temp_f: string;
    temp_c: string;
    condition: {
      icon: string;
      text: string;
    };
  };
};

const WeatherWidget: FC<WeatherProps> = ({ weather }) => {
  if (!weather) {
    return null;
  }
  return (
    <Wrapper href="/weather">
      <DateTime
        type="day"
        time={new Date(weather?.current.last_updated_epoch * 1000)}
      />
      <Icon
        src={weather.current?.condition.icon}
        alt={weather.current?.condition.text}
      />
      <TemperatureWrapper>
        <CelsiusWrapper>
          {weather.current.temp_c}
          <span>&#8451;</span>
        </CelsiusWrapper>
      </TemperatureWrapper>
    </Wrapper>
  );
};

export default WeatherWidget;
