import { FC, useState, useRef, useEffect } from "react";
import { Arrow, Title } from "./DropDown.styled";
import useClickOutside from "common/hooks/useClickOutside";
import Children from "./Children/Children";
import { usePrevious } from "common/hooks/usePrevious";
import { useMountedRef } from "common/hooks/useMountedRef";

interface Props {
  children: any;
  title?: any;
  col?: number;
  value?: any;
  Icon?: any;
  arrow?: boolean;
  type?: string;
}

const DropDown: FC<Props> = ({
  title,
  children,
  arrow = true,
  Icon,
  col,
  value,
  type = "form",
}) => {
  const [open, setOpen] = useState(false);
  const prevState: string = usePrevious<string>(value);
  const mountedRef = useMountedRef();
  const mainEl = useRef(null);
  useClickOutside(mainEl, () => setOpen(false));

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (prevState !== value && mountedRef.current) {
      handleToggle();
    }
  }, [value]);

  return (
    <div style={{ position: "relative" }} ref={mainEl}>
      <Title type={type} open={open} onClick={handleToggle}>
        <span style={{ display: "flex", alignContent: "center" }}>
          {" "}
          {title && title}
          {Icon && <Icon />}
          {arrow && <Arrow open={open} />}
        </span>
      </Title>
      <Children col={col} type={type} open={open}>
        {children}
      </Children>
    </div>
  );
};

export default DropDown;
